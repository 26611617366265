import React from 'react'
import { withTranslation } from 'react-i18next';

import {Footer, SignupDivider, HeroPlain, Feature, VideoSection, Video, GetStarted} from '../../components';
import {WebLayout} from '../../layouts';

const FeaturesPage = ({t}) => (
  <WebLayout alwaysSticky={true}
    title="Tarjoustyökalu - Luo tarjoukset 60 sekunnissa - Duuers"
    meta={[
      {name: 'description', content: 'Laadukas tarjoustyökalu - Duuers tarjoaa tarjoustyökaluohjelmiston pienille yrityksille. Nopea ja helppo käyttää. Rekisteröidy ilmaiseksi nyt!'},
      {name: 'keywords', content: ''},
    ]}
    >
    <HeroPlain
      link={[{
          rel: "alternate", href: "https://www.duuers.com/fi/features", hreflang: "fi"
      },{
          rel: 'alternate', href: 'https://www.duuers.com/en/features', hreflang: 'en'
      },{
          rel: 'alternate', href: 'https://www.duuers.com/en/features', hreflang: 'x-default'
      }]}
      >
      <h1 className={"font_second semibold font40 white"}>{t('features.header')}</h1>
      <p className="font20 padding_top20 opacity9 text white">
        {t('features.text')}
      </p>
      <Video src="https://www.youtube.com/embed/23hKTzrJLPQ?autoplay=1"/>
    </HeroPlain>
    <GetStarted/>
    <Feature 
      name={t('component.features.editor.name')} 
      descriptions={[
      'Tarjoustyökalu, joka vain paranee käytön myötä.',
      'Tallenna ensin suosituimmat tarjouspohjasi (voimme luoda ja tallentaa ne puolestasi) ja käytä niitä nopeuttaaksesi tarjousten lähettämistä.',
      'Voit helposti siirrellä tarjouksen elementtejä ja muokata sisällön haluamaksesi.'
      ]}
      srcSet="/images/features/editor-fi.png 1x, /images/features/editor-fi@2x.png 2x, /images/features/editor-fi@3x.png 3x" src="/images/features/editor-fi.png">
    </Feature>
    <Feature 
      bg="light_blue"
      reverse
      name={t('component.features.contentLibrary.name')} 
      descriptions={[
        'Luo ja tallenna omat hinnastot, kuvakirjastot, videot ja vaikka yritysesittely.',
        'Tallennetut elementit ovat lisättävissä tarjoukseen yhdellä napin painalluksella.'
      ]}
      srcSet="/images/features/elements-fi.png 1x, /images/features/elements-fi@2x.png 2x, /images/features/elements-fi@3x.png 3x" src="/images/features/elements-fi.png">
    </Feature>
    <Feature 
      name={t('component.features.liveEdit.name')} 
      descriptions={[
        'Anna asiakkaasi itse valita antamistasi vaihtoehdoista ne tuotteet ja palvelut, jotka hän haluaa.',
        'Muokkaa ja päivitä lähettämiäsi tarjouksia.'
      ]}
      srcSet="/images/features/live-edit-fi_.png 1x, /images/features/live-edit-fi@2x_.png 2x, /images/features/live-edit-fi@3x_.png 3x" src="/images/features/live-edit-fi_.png">
    </Feature>
    <Feature 
      bg="light_blue"
      reverse
      name={t('component.features.chat.name')} 
      descriptions={[
        'Keskustele asiakkaasi kanssa suoraan työkalussa.',
        'Asiakkaasi voivat kommentoida tarjousta, kysyä tai antaa palautetta suoraan tarjouksen yhteydessä.',
      ]}
      srcSet="/images/features/chat-fi_.png 1x, /images/features/chat-fi@2x_.png 2x, /images/features/chat-fi@3x_.png 3x" src="/images/features/chat-fi_.png">
    </Feature>
    <Feature 
      name={t('component.features.followUp.name')} 
      descriptions={[
        'Näe heti milloin asiakas on avannut tai hyväksynyt tarjouksen.',
        'Saa ilmoitukset heti kun asiakas esittää kysymyksen tarjouksesta',
        'Saa tieto kun tarjoukset ovat erääntymäisillään tai jos niitä ei ole vielä avattu.',
      ]}
      srcSet="/images/features/follow-up-fi.png 1x, /images/features/follow-up-fi@2x.png 2x, /images/features/follow-up-fi@3x.png 3x" src="/images/features/follow-up-fi.png">
    </Feature>
    <Feature 
      bg="light_blue"
      reverse
      name={t('component.features.teamwork.name')} 
      descriptions={[
        'Kutsu muut tiimin jäsenet mukaan.',
        'Näe kuka hoitaa mitäkin tarjousta ja tuuraa muita kun he ovat poissa lomalla tai sairaana.',
        'Hae tarjouksia käyttäjien, tagien ja tarjouksen statuksen mukaan.'
      ]}
      srcSet="/images/features/teamwork-fi.png 1x, /images/features/teamwork-fi@2x.png 2x, /images/features/teamwork-fi@3x.png 3x" src="/images/features/teamwork-fi.png">
    </Feature>
    <Feature 
      name={t('component.features.branding.name')} 
      descriptions={[
      'Lisää tarjoukseen yrityksesi logo, taustakuva ja brändivärit',
      'Lisää helposti kuvia, liitetiedostoja ja muuta rikasta sisältöä, joka tekee tarjouksesta helppolukuisen ja vaikuttavan näköisen.'
      ]}
      srcSet="/images/features/branding-fi_.png 1x, /images/features/branding-fi@2x_.png 2x, /images/features/branding-fi@3x_.png 3x" src="/images/features/branding-fi_.png">
    </Feature>
    <Feature 
      bg="light_blue"
      reverse
      name={t('component.features.video.name')} 
      descriptions={[
        'Liitä vain videon linkki (YouTube, Vimeo, tai joku muu) ja me teemme loput.',
        'Esittele videoiden avulla yrityksesi ja tuotteesi.'
      ]}
      srcSet="/images/features/video-fi_.png 1x, /images/features/video-fi@2x_.png 2x, /images/features/video-fi@3x_.png 3x" src="/images/features/video-fi_.png">
    </Feature>
    <VideoSection src="https://www.youtube.com/embed/0jA0GbKJNq0?autoplay=1" poster="/images/video-poster.png"/>
    <SignupDivider/>
    <Footer/>
  </WebLayout>
)

export default withTranslation()(FeaturesPage)
